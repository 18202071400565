@import "./tailwind.css";
.pagination {
  display: flex;
  justify-content: space-between;
}

.align-items-right {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.8rem;
}

.align-items-space-between {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.btn-group > div {
  margin-left: 0.8rem;
}

.center-loading {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-white {
  background-color: red;
}
.fs-20 {
  font-size: 1.5rem;
}

.portal-card {
  background-color: #fff;
  box-shadow: 3px 3px 7px;
  min-height: 150px;
}
.portal-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portal-card-body > h6 {
  font-size: 1.1rem;
  font-weight: 700;
}

.horizantal {
  border: thin solid lightgray;
  width: 10rem;
}

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .portal-card {
    background-color: #fff;
    margin-top: 1rem;
    box-shadow: 0px 0px 0px;
  }
}
@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}
@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .portal-card {
    background-color: #fff;
    box-shadow: 3px 3px 7px;
    min-height: 150px;
  }
}
